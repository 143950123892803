import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpErrorResponse, HttpContextToken
} from '@angular/common/http';
import {catchError, from, Observable, switchMap, take, tap, throwError} from 'rxjs';
import {OAuthService} from 'angular-oauth2-oidc';
import {FsxB2CDocumentDiscoveryURL} from '../auth/config/auth.config';

/**
 * Context token to allow a specific request to bypass the token injection.
 */
export const BYPASS_TOKEN_INJECTION = new HttpContextToken(() => false);

@Injectable()
export class FSXInterceptor implements HttpInterceptor {

  public constructor(private readonly oAuthService: OAuthService) {}

  /**
   * Intercept any requests and add the bearer token to the header.
   * @param request
   * @param next
   */
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const authToken = this.oAuthService.getAccessToken() ?? null;
    if (!request.context.get(BYPASS_TOKEN_INJECTION)) {
      request = request.clone({
        headers: request.headers.set('Authorization', `Bearer ${authToken}`),
      });
    }
    return next.handle(request).pipe(
      catchError((httpError: HttpErrorResponse) => {
        if (httpError.status === 401) {
          return this.retryLogin(request, next);
        }
        return throwError(httpError);
      })
    );
  }

  /**
   * Retry the login if we get a 401 error.
   * @param request
   * @param next
   * @private
   */
  private retryLogin(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return from(this.oAuthService.loadDiscoveryDocument(FsxB2CDocumentDiscoveryURL)).pipe(
      switchMap(() => next.handle(request)),
      take(1)
    );
  }
}
