import {CanActivateFn, Router} from '@angular/router';
import {OAuthService} from 'angular-oauth2-oidc';
import {inject} from '@angular/core';

export const redirectGuard: CanActivateFn = (route, state) => {
  const oAuthService = inject(OAuthService);

  const router = inject(Router);

  if (oAuthService.hasValidAccessToken() && !!oAuthService.state) {
    // Extract the state and redirect and redirect to route
    const savedUrl = oAuthService.state;
    oAuthService.state = '';

    const decodedUrl = decodeURIComponent(savedUrl);
    const segments = decodedUrl.split('/').filter(segment => segment.trim() !== '');

    return router.navigate(segments)
  } else {
    return true;
  }
};
