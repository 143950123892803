import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { UserModel } from 'src/app/types/api/dot-net-api-types/data-contracts';

@Injectable({
  providedIn: 'root'
})
export class UserDataService {
  private sessionUser$ = new ReplaySubject<UserModel | null>(1);

  public get sessionUser(): Observable<UserModel | null> {
    return this.sessionUser$.asObservable();
  }

  public set sessionUser(user: UserModel | null) {
    this.sessionUser$.next(user);
  }

  public completeSessionUser() {
    this.sessionUser$.complete();
  }
}