export enum ENVIRONMENT {
    DEV = 'dev',
    PROD = 'production',
    UAT = 'uat',
  }

  export interface EnvConfig {
    ApiServer: string;
    Environment: {
      Env: string;
    };
    MAX_FILE_SIZE: number;
    production: boolean;
  }
