import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {
  EmailLogModel, UserConnectionPostRequest, UserConnectionPostRequestBatch, UserConnectionResponse,
  UserListRequest,
  UserModel,
  UserRejectionModel
} from '../../../types/api/dot-net-api-types/data-contracts';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {userEndpoints} from '../../../types/api/endpoints/user.endpoints';

/**
 * This is a stub for the UsersApiService.
 */
export interface IUsersApiService {
  activateUsers(userIds: string[]): Observable<Object>;
  approveUsers(userIds: string[]): Observable<Object>;
  createUser(user: UserModel): Observable<UserModel>;
  deactivateUsers(userIds: string[]): Observable<Object>;
  deleteUsers(userIds: string[]): Observable<Object>;
  getEmailHistory(userId: string): Observable<EmailLogModel[]>;
  getNewUsers(searchBy?: string): Observable<UserModel[]>;
  getMatchingUsers(userId: string): Observable<UserModel[]>;
  getUserInfo(userId: string): Observable<UserModel>;
  rejectUsers(userRejections: UserRejectionModel[]): Observable<Object>;
  resendWelcomeEmail(userId: string): Observable<Object>;
  updateUser(user: UserModel): Observable<UserModel>;
  userList(userListRequest: UserListRequest): Observable<UserModel[]>;
  userAcceptTerms(): Observable<Object>;
  postConnection(fromUserId: string, toUserId: string): Observable<UserConnectionResponse[]>;
  batchPostConnection(fromUserId: string, toUserIds: string[]): Observable<UserConnectionResponse[]>;
  deleteConnection(userId: string): Observable<UserConnectionResponse[]>;
  getConnections(userId: string): Observable<UserConnectionResponse[]>;
}
/**
 * Base url for the api.
 */
const BASE_URL = environment.ApiServer;
/**
 * Users Endpoints for the api.
 */
const USERS_ENDPOINTS = userEndpoints(BASE_URL);

@Injectable({
  providedIn: 'root'
})
export class UsersApiService implements IUsersApiService {
  constructor(private readonly http: HttpClient) {}

  /**
   * Endpoint to activate a user.
   * @param userIds
   */
  activateUsers(userIds: string[]): Observable<Object> {
    const url = USERS_ENDPOINTS.activateUser();
    return <Observable<Object>>this.http.put(url, userIds);
  }

  /**
   * Endpoint to approve a user.
   * @param userIds
   */
  approveUsers(userIds: string[]): Observable<Object> {
    const url = USERS_ENDPOINTS.approveUser();
    return <Observable<Object>>this.http.put(url, userIds);
  }

  createUser(user: UserModel): Observable<UserModel> {
    const url = USERS_ENDPOINTS.createUser();
    return <Observable<UserModel>>this.http.post(url, user);
  }

  /**
   * Endpoint to deactivate a user.
   * @param userIds
   */
  deactivateUsers(userIds: string[]): Observable<Object> {
    const url = USERS_ENDPOINTS.deActivateUser();
    return <Observable<Object>>this.http.put(url, userIds);
  }

  /**
   * Endpoint to delete a user.
   * @param userIds
   */
  deleteUsers(userIds: string[]): Observable<Object> {
    const url = USERS_ENDPOINTS.deleteUser();
    return <Observable<Object>>this.http.delete(url, {
      body: userIds
    });

  }

  /**
   * Endpoint to get email history.
   * @param userId
   */
  getEmailHistory(userId: string): Observable<EmailLogModel[]> {
    const url = USERS_ENDPOINTS.emailLog(userId);
    return <Observable<EmailLogModel[]>>this.http.get(url);
  }

  /**
   * Endpoint to get new users.
   * @param searchBy
   */
  getNewUsers(searchBy?: string): Observable<UserModel[]> {
    const url = USERS_ENDPOINTS.newUsers();
    // Still viable?
    const params: HttpParams = new HttpParams();
    if (searchBy) {
      params.set('searchBy', searchBy);
    }
    return <Observable<UserModel[]>>this.http.get(url);
  }

  /**
   * Endpoint to get matching users.
   * @param userId
   */
  getMatchingUsers(userId: string): Observable<UserModel[]> {
    const url = USERS_ENDPOINTS.matchingUsers(userId);
    return <Observable<UserModel[]>>this.http.get(url);
  }

  /**
   * Endpoint to get user info.
   * @param userId
   */
  getUserInfo(userId: string): Observable<UserModel> {
    const url = USERS_ENDPOINTS.userInfo(userId);
    return <Observable<UserModel>>this.http.get(url);
  }

  /**
   * Endpoint to reject a user.
   * @param userRejections
   */
  rejectUsers(userRejections: UserRejectionModel[]): Observable<Object> {
    const url = USERS_ENDPOINTS.rejectUser();
    return <Observable<Object>>this.http.put(url, userRejections);
  }

  /**
   * Endpoint to resend a welcome email.
   * @param userId
   */
  resendWelcomeEmail(userId: string): Observable<Object> {
    const url = USERS_ENDPOINTS.resendWelcomeEmail(userId);
    return <Observable<Object>>this.http.post(url, {});
  }

  /**
   * Endpoint to update a user.
   * @param user
   */
  updateUser(user: UserModel): Observable<UserModel> {
    const url = USERS_ENDPOINTS.updateUser();
    return <Observable<UserModel>>this.http.put(url, user);
  }

  /**
   * Endpoint to get a list of users.
   * @param userListRequest
   */
  userList(userListRequest: UserListRequest): Observable<UserModel[]> {
    const url = USERS_ENDPOINTS.userList();
    return <Observable<UserModel[]>>this.http.post(url, userListRequest);
  }

  /**
   * Endpoint to accept terms.
   */
  userAcceptTerms(): Observable<Object> {
    const url = USERS_ENDPOINTS.userAcceptTerms();
    return <Observable<Object>>this.http.put(url, {});
  }

  /**
   * Endpoint to post connections.
   * @param fromUserId The user id of the user making the connection, (I.E. a judge)
   * @param toUserId The user id of the user being connected to, (I.E. an assistant councel)
   */
  postConnection(fromUserId: string, toUserId: string): Observable<UserConnectionResponse[]> {
    const url = USERS_ENDPOINTS.connections();
    const data: UserConnectionPostRequest = {
      fromUserId: fromUserId,
      toUserId: toUserId
    }
    return <Observable<UserConnectionResponse[]>>this.http.post(url, data);
  }

  /**
   * Endpoint to batch post connections.
   * @param fromUserId
   * @param toUserIds
   */
  batchPostConnection(fromUserId: string, toUserIds: string[]): Observable<UserConnectionResponse[]> {
    const url = USERS_ENDPOINTS.batchConnections();
    const data: UserConnectionPostRequestBatch = {
      fromUserId: fromUserId,
      toUserIds: toUserIds
    };
    return <Observable<UserConnectionResponse[]>>this.http.post(url, data);
  }

  /**
   * Endpoint to delete a connection.
   * @param userId The id of the user that has a connection (i.e. an assistant councel)
   */
  deleteConnection(userId: string): Observable<UserConnectionResponse[]> {
    const url = USERS_ENDPOINTS.connections(userId);
    return <Observable<UserConnectionResponse[]>>this.http.delete(url, {});
  }

  /**
   * Endpoint to get a connection.
   * @param userId The id of the user that has a connection (i.e. judge)
   */
  getConnections(userId:string): Observable<UserConnectionResponse[]> {
    const url = USERS_ENDPOINTS.connections(userId);
    return <Observable<UserConnectionResponse[]>>this.http.get(url);
  }
}
