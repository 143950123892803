import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

export interface IErrorsStateService {
  errorMessage$: Observable<string>;
  showError(error: string): void;
}

@Injectable({
  providedIn: 'root'
})

export class ErrorsStateService implements IErrorsStateService {

  constructor() { }

  private error$$ = new Subject<string>();
  errorMessage$: Observable<string> = this.error$$.asObservable();


  showError(error: string) {
    this.error$$.next(error);
  }
}
