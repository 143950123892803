import {CanActivateFn, Router} from '@angular/router';
import {inject} from '@angular/core';
import {AuthService} from '../../auth/auth.service';
import {ROUTES} from '../../../../routes';
import {OAuthService} from 'angular-oauth2-oidc';
import {of, tap} from 'rxjs';
import {map} from 'rxjs/operators';

export const azureB2CGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const authService = inject(AuthService);
  const oAuthService = inject(OAuthService);
  // Get claims (for comparisons ease)
  const needsRegistration = authService.getIdentityClaim(IIdentityClaim.NeedsRegistration, true);
  const registrationPending = authService.getIdentityClaim(IIdentityClaim.RegistrationPending);
  const hasBlockText = authService.getIdentityClaim(IIdentityClaim.BlockText);
  const termsAccepted = authService.getIdentityClaim(IIdentityClaim.TermsAccepted);


  // If NeedsRegistration is true, go to registration
  if (needsRegistration === true || needsRegistration === 'True') {
    return router.navigate([ROUTES.REGISTER]);
  }
  // If RegistrationPending is true, go to page that tells them their registration is pending
  else if (registrationPending === true || registrationPending === 'True') {
    return router.navigate([ROUTES.MESSAGE]);
  }
  // If BlockText has  value, route to page that shows error
  else if (hasBlockText) {
    return router.navigate([ROUTES.MESSAGE]);
  }
  else if (!!oAuthService.getIdentityClaims() && oAuthService.getIdentityClaims().hasOwnProperty(IIdentityClaim.TermsAccepted) && (termsAccepted === false || termsAccepted === 'False')) {
    const acceptedTerms = sessionStorage.getItem('termsAccepted');
    if (acceptedTerms === 'true') {
      return true;
    }
    return router.navigate([ROUTES.REGISTER, {outlets: {'registeroutlet': 'terms'}}]);
  }
  else if (authService.getIdentityClaim(IIdentityClaim.AgencyUserID)) {
    return true;
  }
  else {
    return of(() => {
      authService.logout();
    }).pipe(
      tap(() => authService.runInitialLoginSequence()),
      map(() => false)
    );
  }
};

export enum IIdentityClaim {
  NeedsRegistration = 'extension_NeedsRegistration',
  RegistrationPending = 'extension_RegistrationPending',
  BlockText = 'extension_BlockText',
  AgencyUserID = 'extension_AgencyUserID',
  TermsAccepted = 'extension_TermsAccepted'
}
