import {ENVIRONMENT} from '../app/core/config/environments.config';

export const environment = {
  ApiServer: "https://api.agencies.uat.fileandserve.com/",
  Environment: {
    Env: ENVIRONMENT.UAT,
  },
  MAX_FILE_SIZE: 1_000_000,
  production: true,
}
