import {CanActivateFn, Router} from '@angular/router';
import {AuthService} from '../../auth/auth.service';
import {inject} from '@angular/core';
import {IIdentityClaim} from '../azure-b2c/azure-b2c-guard';
import {ROUTES} from '../../../../routes';

/**
 * The message guard to check if the user has a block text claim.
 * @param route
 * @param state
 */
export const messageGuard: CanActivateFn = (route, state) => {
  /**
   * The auth service to get claims.
   */
  const authService = inject(AuthService);
  /**
   * The router to navigate to redirect url.
   */
  const router = inject(Router);
  // If BlockText has  value, route to page that shows error
  if (authService.getIdentityClaim(IIdentityClaim.BlockText)) {
    return true;
  }
  // Else go back to root
  // ToDO: May cause a loop? Need to check.
  return router.navigate([ROUTES.APP]);
};
