import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

export interface IGenericStateService {
  genericMessage$: Observable<string>;
  showGeneric(message: string): void;
}

@Injectable({
  providedIn: 'root'
})
export class GenericStateService implements IGenericStateService{

  constructor() { }

  private generic$$ = new Subject<string>();
  genericMessage$: Observable<string> = this.generic$$.asObservable();


  showGeneric(message: string) {
    this.generic$$.next(message);
  }
}
