import { bootstrapApplication } from "@angular/platform-browser";
import { AppComponent } from "./app/app.component";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideRouter } from "@angular/router";
import {APP_INITIALIZER, importProvidersFrom} from "@angular/core";
import { APP_ROUTES } from "./routes";
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {provideOAuthClient} from 'angular-oauth2-oidc';
import {AuthService} from './app/core/auth/auth.service';
import {authAppInitializerFactory} from './app/core/auth/auth-app-initializer.factory';
import {FSXInterceptor} from './app/core/interceptors/fsx.interceptor.service';
import {MAT_RIPPLE_GLOBAL_OPTIONS} from '@angular/material/core';
import {FsxGlobalRippleOptions} from './app/services/material/fsx-global-ripple-options.service';

bootstrapApplication(AppComponent, {
  providers: [
    {
      provide: MAT_RIPPLE_GLOBAL_OPTIONS, useExisting: FsxGlobalRippleOptions
    },
    { provide: HTTP_INTERCEPTORS, useClass: FSXInterceptor, multi: true },
    provideRouter(APP_ROUTES),
    importProvidersFrom([BrowserAnimationsModule, HttpClientModule]),
    provideOAuthClient(),
    { provide: APP_INITIALIZER, useFactory: authAppInitializerFactory, deps: [AuthService], multi: true },
  ],
}).catch(err => console.error(err));
