import { AuthConfig } from 'angular-oauth2-oidc';
export const FsxB2CDocumentDiscoveryURL = 'https://fileandserveagenciesuat.b2clogin.com/FileAndServeAgenciesUat.onmicrosoft.com/B2C_1A_EMAIL_SIGNIN/v2.0/.well-known/openid-configuration';
const FsxB2CClientId = 'f1b967d4-bb2b-415e-b26e-eda19ba6cf05';
const FsxB2CScopes = 'openid profile email offline_access https://FileAndServeAgenciesUat.onmicrosoft.com/f1b967d4-bb2b-415e-b26e-eda19ba6cf05/access_as_user';
const FsxB2CIssuer = 'https://fileandserveagenciesuat.b2clogin.com/63aae4a8-3949-4f73-abcf-8298d512f5ed/v2.0/'
const FsxB2CLoginUrl = 'https://fileandserveagenciesuat.b2clogin.com/FileAndServeAgenciesUat.onmicrosoft.com/B2C_1A_EMAIL_SIGNIN/oauth2/v2.0/authorize';
const FsxB2CLogoutUrl = 'https://fileandserveagenciesuat.b2clogin.com/FileAndServeAgenciesUat.onmicrosoft.com/signout/B2C_1A_EMAIL_SIGNIN'

export const authConfig: AuthConfig = {
  // ToDo: Need to store these magic strings in environment variables.
  issuer: FsxB2CIssuer,
  loginUrl: FsxB2CLoginUrl,
  logoutUrl: FsxB2CLogoutUrl,
  // ToDo: I believe we need to register all possible returnUri's in the Azure AD B2C App Registration
  redirectUri: window.location.origin + '/index.html',
  clientId: FsxB2CClientId,
  responseType: 'code',
  strictDiscoveryDocumentValidation: false,
  scope: FsxB2CScopes,
  showDebugInformation: false,
  skipIssuerCheck: false,
  // timeoutFactor: .01
}
