import { Injectable } from '@angular/core';
import {RippleGlobalOptions} from '@angular/material/core';

@Injectable({
  providedIn: 'root'
})
export class FsxGlobalRippleOptions implements RippleGlobalOptions {
  /**
   * Whether ripples should be disabled. Ripples can be disabled globally.
   */
  disabled: boolean = false;
}
