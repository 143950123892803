/* eslint-disable brace-style */

import { Injectable } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { OAuthErrorEvent, OAuthService } from 'angular-oauth2-oidc';
import {BehaviorSubject, catchError, combineLatest, EMPTY, Observable, switchMap, take, tap} from 'rxjs';
import { filter, map } from 'rxjs/operators';
import {authConfig, FsxB2CDocumentDiscoveryURL} from './config/auth.config';
import {IIdentityClaim} from '../guards/azure-b2c/azure-b2c-guard';
import {UsersApiService} from '../../services/api/users/users-api.service';
import { UserDataService } from 'src/app/services/ui-state/user-data/user-data.service';
import {environment} from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private isAuthenticatedSubject$ = new BehaviorSubject<boolean>(false);
  public isAuthenticated$ = this.isAuthenticatedSubject$.asObservable();

  private isDoneLoadingSubject$ = new BehaviorSubject<boolean>(false);
  public isDoneLoading$ = this.isDoneLoadingSubject$.asObservable();

  /**
   * Publishes `true` if and only if (a) all the asynchronous initial
   * login calls have completed or errorred, and (b) the user ended up
   * being authenticated.
   *
   * In essence, it combines:
   *
   * - the latest known state of whether the user is authorized
   * - whether the ajax calls for initial log in have all been done
   */
  public canActivateProtectedRoutes$: Observable<boolean> = combineLatest([
    this.isAuthenticated$,
    this.isDoneLoading$
  ]).pipe(map(values => values.every(b => b)));

  private navigateToLoginPage() {
    // TODO: Remember current URL
    this.router.navigateByUrl('/should-login');
  }

  constructor(
    private oauthService: OAuthService,
    private router: Router,
    private readonly usersApiService: UsersApiService,
    private userDataService: UserDataService,
    private activatedRoute: ActivatedRoute
  ) {
    // Useful for debugging:
    this.oauthService.events.subscribe(event => {
      if (event instanceof OAuthErrorEvent) {
        console.error('OAuthErrorEvent Object:', event);
      } else {
        // console.warn('OAuthEvent Object:', event);
      }
    });

    // THe following cross-tab communication of fresh access tokens works usually in practice,
    // but if you need more robust handling the community has come up with ways to extend logic
    // in the library which may give you better mileage.
    //
    // See: https://github.com/jeroenheijmans/sample-angular-oauth2-oidc-with-auth-guards/issues/2
    //
    // Until then we'll stick to this:
    window.addEventListener('storage', (event) => {
      // The `key` is `null` if the event was caused by `.clear()`
      if (event.key !== 'access_token' && event.key !== null) {
        return;
      }

      console.warn('Noticed changes to access_token (most likely from another tab), updating isAuthenticated');
      this.isAuthenticatedSubject$.next(this.oauthService.hasValidAccessToken());

      if (!this.oauthService.hasValidAccessToken()) {
        this.navigateToLoginPage();
      }
    });

    this.oauthService.events
      .subscribe(_ => {
        this.isAuthenticatedSubject$.next(this.oauthService.hasValidAccessToken());
      });
    this.isAuthenticatedSubject$.next(this.oauthService.hasValidAccessToken());

    this.oauthService.events
      .pipe(filter(e => ['token_received'].includes(e.type)))
      .subscribe(e => this.oauthService.loadUserProfile());

    this.oauthService.events
      .pipe(filter(e => ['session_terminated', 'session_error'].includes(e.type)))
      .subscribe(e => this.navigateToLoginPage());

    this.oauthService.setupAutomaticSilentRefresh();
  }

  public runInitialLoginSequence(): Promise<void> {
    // console.log(window.location.pathname);
    this.oauthService.configure(authConfig);
    this.oauthService.setupAutomaticSilentRefresh();
    // The convenience method mentioned in the docs (loadDiscoveryDocumentAndLogin) won't work
    // since we need a way to modify the token endpoint
    return this.oauthService
      .loadDiscoveryDocument(FsxB2CDocumentDiscoveryURL)
      .then(async (_) => {
        return await this.oauthService.tryLogin();
      })
      // Check if we have a valid access token. If not, do something.
      .then((_) => {
        if (!this.oauthService.hasValidAccessToken()) {
          return this.oauthService.initLoginFlow(window.location.pathname);
        }
      })
      .then(() => {
        const agencyUserID = this.getIdentityClaim(IIdentityClaim.AgencyUserID) as string;
        if (!!agencyUserID) {
          this.usersApiService.getUserInfo(agencyUserID)
            .pipe(
              take(1),
              tap(user => {
                this.userDataService.sessionUser = user;
                this.isDoneLoadingSubject$.next(true);
              })
            )
            .subscribe()
          ;
        }

        // Check for the strings 'undefined' and 'null' just to be sure. Our current
        // login(...) should never have this, but in case someone ever calls
        // initImplicitFlow(undefined | null) this could happen.
        // if (this.oauthService.state && this.oauthService.state !== 'undefined' && this.oauthService.state !== 'null') {
        //   let stateUrl = this.oauthService.state;
        //   if (stateUrl.startsWith('/') === false) {
        //     stateUrl = decodeURIComponent(stateUrl);
        //   }
        //   console.log(`There was state of ${this.oauthService.state}, so we are sending you to: ${stateUrl}`);
        //   this.router.navigateByUrl(stateUrl);
        // }
      })
      // If any error occurs, catch it to prevent the app from crashing, and init code flow.
      .catch((err) => {
        console.error('Error loading discovery document: ', err);
        this.oauthService.initCodeFlow(`state=${window.location.pathname}`);
        this.isDoneLoadingSubject$.next(true)
      })
    ;
    // if (location.hash) {
    //   console.log('Encountered hash fragment, plotting as table...');
    //   console.table(location.hash.substr(1).split('&').map(kvp => kvp.split('=')));
    // }
    //
    // // 0. LOAD CONFIG:
    // // First we have to check to see how the IdServer is
    // // currently configured:
    // return this.oauthService.loadDiscoveryDocument()
    //
    //   // For demo purposes, we pretend the previous call was very slow
    //   .then(() => new Promise<void>(resolve => setTimeout(() => resolve(), 1500)))
    //
    //   // 1. HASH LOGIN:
    //   // Try to log in via hash fragment after redirect back
    //   // from IdServer from initImplicitFlow:
    //   .then(() => this.oauthService.tryLogin())
    //
    //   .then(() => {
    //     if (this.oauthService.hasValidAccessToken()) {
    //       return Promise.resolve();
    //     }
    //
    //     // 2. SILENT LOGIN:
    //     // Try to log in via a refresh because then we can prevent
    //     // needing to redirect the user:
    //     return this.oauthService.silentRefresh()
    //       .then(() => Promise.resolve())
    //       .catch(result => {
    //         // Subset of situations from https://openid.net/specs/openid-connect-core-1_0.html#AuthError
    //         // Only the ones where it's reasonably sure that sending the
    //         // user to the IdServer will help.
    //         const errorResponsesRequiringUserInteraction = [
    //           'interaction_required',
    //           'login_required',
    //           'account_selection_required',
    //           'consent_required',
    //         ];
    //
    //         if (result
    //           && result.reason
    //           && errorResponsesRequiringUserInteraction.indexOf(result.reason.error) >= 0) {
    //
    //           // 3. ASK FOR LOGIN:
    //           // At this point we know for sure that we have to ask the
    //           // user to log in, so we redirect them to the IdServer to
    //           // enter credentials.
    //           //
    //           // Enable this to ALWAYS force a user to login.
    //           // this.login();
    //           //
    //           // Instead, we'll now do this:
    //           console.warn('User interaction is needed to log in, we will wait for the user to manually log in.');
    //           return Promise.resolve();
    //         }
    //
    //         // We can't handle the truth, just pass on the problem to the
    //         // next handler.
    //         return Promise.reject(result);
    //       });
    //   })
    //
    //   .then(() => {
    //     this.isDoneLoadingSubject$.next(true);
    //
    //     // Check for the strings 'undefined' and 'null' just to be sure. Our current
    //     // login(...) should never have this, but in case someone ever calls
    //     // initImplicitFlow(undefined | null) this could happen.
    //     if (this.oauthService.state && this.oauthService.state !== 'undefined' && this.oauthService.state !== 'null') {
    //       let stateUrl = this.oauthService.state;
    //       if (stateUrl.startsWith('/') === false) {
    //         stateUrl = decodeURIComponent(stateUrl);
    //       }
    //       console.log(`There was state of ${this.oauthService.state}, so we are sending you to: ${stateUrl}`);
    //       this.router.navigateByUrl(stateUrl);
    //     }
    //   })
    //   .catch(() => this.isDoneLoadingSubject$.next(true));
  }

  /**
   * Get the identity claims from Azure B2C and return it.
   * @param claim
   * @param log Flag to log claims to console
   */
  public getIdentityClaim(claim: IIdentityClaim, log = false): string | boolean {
    const claims = this.oauthService.getIdentityClaims();
    if (!claims) return '';
    return claims[claim];
  }

  public login(targetUrl?: string) {
    // Note: before version 9.1.0 of the library you needed to
    // call encodeURIComponent on the argument to the method.
    this.oauthService.initLoginFlow();
  }

  public logout() {
    sessionStorage.removeItem('termsAccepted');
    this.oauthService.logOut();
    this.userDataService.completeSessionUser();
  }
  public refresh() { this.oauthService.silentRefresh(); }
  public hasValidToken() { return this.oauthService.hasValidAccessToken(); }

  // These normally won't be exposed from a service like this, but
  // for debugging it makes sense.
  public get accessToken() { return this.oauthService.getAccessToken(); }
  public get refreshToken() { return this.oauthService.getRefreshToken(); }
  public get identityClaims() { return this.oauthService.getIdentityClaims(); }
  public get idToken() { return this.oauthService.getIdToken(); }
  public get logoutUrl() { return this.oauthService.logoutUrl; }
}
